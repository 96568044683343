var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "special" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("slick", { ref: "slick", attrs: { options: _vm.slickOptions } }, [
        _c("div", { staticClass: "special__slide special-slide" }, [
          _c("div", { staticClass: "special-slide__wrapper" }, [
            _c("div", { staticClass: "special-slide__caption" }, [
              _c("div", { staticClass: "special-slide__caption-content" }, [
                _c("div", { staticClass: "special-slide__title" }, [
                  _vm._v("-10% на аренду Graco Mark V")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "special-slide__text" }, [
                  _vm._v(
                    "Аппарат безвоздушного распыления Mark V предназначен для нанесения материалов высокой и сверхвысокой вязкости с высоким содержанием сухого остатка. Graco Mark V  широко применяется компаниями во всем мире для нанесения огнезащитных, гидроизоляционных, теплоизоляционных, антикоррозийных и других вязких материалов. Универсальность: один распылитель обеспечивает нанесение, как отделочных покрытий, так и шпаклевок. Возможность наносить лаки, акриловые краски, покрытия на масляной основе, эмульсия, эмали, шпаклевки, уретаны, огнезащитные покрытия, латексы, штукатурки для внутренних работ, эпоксидные составы, цинконаполненные составы, мастики, штукатурки для внутренних работ."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "special-slide__button",
                    on: { click: _vm.openModal }
                  },
                  [_vm._v("Заказать по акции")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "special-slide__image" }, [
              _c("img", { attrs: { src: "/assets/img/slide1.jpg" } })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "special__slide special-slide" }, [
          _c("div", { staticClass: "special-slide__wrapper" }, [
            _c("div", { staticClass: "special-slide__caption" }, [
              _c("div", { staticClass: "special-slide__caption-content" }, [
                _c("div", { staticClass: "special-slide__title" }, [
                  _vm._v("-15% на аренду Graco ST MAX II 495")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "special-slide__text" }, [
                  _vm._v(
                    "Graco ST MAX II 495 — поршневой безвоздушный окрасочный аппарат высокого давления, подходит для распыления водорастворимых и акриловых красок, алкидных составов, лаков на водной основе, грунтовок, эпоксидных материалов, олиф, вододисперсионных эмалей, эмульсий, клея, полиуретановых составов, морилки и с других пропитывающих веществ. Область применения окрасочного аппарата Graco ST MAX II 495  достаточно широка: внутренние и наружные окрасочные работы; покраска потолков, пола и стен при квартирном ремонте, обработка древесины, качественные финишные покрытия, антикоррозийная обработка и окраска автомобилей. Аппарат Graco ST MAX II 495 — это отличное решение как для небольших бригад маляров, так и для огромных сервисных строительных центров и компаний."
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "special-slide__button",
                    on: { click: _vm.openModal }
                  },
                  [_vm._v("Заказать по акции")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "special-slide__image" }, [
              _c("img", { attrs: { src: "/assets/img/slide2.jpg" } })
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "special__title-wrapper" }, [
      _c("div", { staticClass: "special__title" }, [_vm._v("Акции")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6365c879", { render: render, staticRenderFns: staticRenderFns })
  }
}