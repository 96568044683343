<template>
    <div class="partners">
        <div class="partners__container">
            <div class="partners__title title">Наши клиенты</div>
            <slick ref="slick" :options="slickOptions">
                <div class="partners__item">
                    <img src="/assets/img/partners1.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners2.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners3.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners4.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners1.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners2.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners3.jpg">
                </div>
                <div class="partners__item">
                    <img src="/assets/img/partners4.jpg">
                </div>
            </slick>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: 'Partners',
        data: function() {
            return {
                slickOptions: {
                    slidesToShow: 4,
                    autoplay: true,
                    prevArrow: '<button type="button" class="slick-prev"></button>',
                    nextArrow: '<button type="button" class="slick-next"></button>',
                    responsive: [
                        {
                          breakpoint: 720,
                          settings: {
                            slidesToShow: 2,
                          }
                        },
                        {
                          breakpoint: 540,
                          settings: {
                            slidesToShow: 1,
                          }
                        },
                    ]
                },
            }
        }, 
        components: {
            Slick
        },
        methods: {
            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },

            reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
        },
        created() {
            
        }
    }
</script>

<style lang="scss">
    @import "../scss/layout/layout";
    @import "../../node_modules/slick-carousel/slick/slick.css";

    .partners {

        &__container {
            @include container;
            padding-top: 115px;
            padding-bottom: 100px;
        }

        & .slick-slider {
            margin-top: 50px;
            display: flex;
            align-items: center;
        }

        & .slick-list {
            width: 100%;
        }

        & .slick-slide img {
            margin: 0 auto;
            max-width: 100%
        }

        & .slick-arrow {
            width: 50px;
            height: 50px;
            background: #0087d2;
            border-radius: 25px;
            border: none;
            flex-basis: 50px;
            flex-shrink: 0;
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                width: 13px;
                height: 18px;
                position: relative;
                top: 1px;
            }

            &.slick-prev:before {
                background: url(/assets/img/arrow-left.png);
                left: -2px;
            }

            &.slick-next:before {
                background: url(/assets/img/arrow-right.png);
                left: 2px;
            }
        }
    }
</style>