<template>
    <div class="catalog">
        <div class="catalog__container">
            <div class="catalog__menu">
                <div class="catalog__menu-item" v-for="section in catalog" @click="currentSection = section;" :class="{'catalog__menu-item--active' : currentSection == section}">
                    <div class="catalog__menu-icon"><img :src="section.icon"></div>
                    <div class="catalog__menu-title">{{ section.title }}</div>
                </div>
            </div>
            <div class="catalog__list">
                <div class="catalog__item catalog-item" v-for="item in currentSection.items">
                    <div class="catalog-item__image"><img :src="item.image"></div>
                    <div class="catalog-item__content">
                        <div class="catalog-item__title">
                            {{ item.name }} <span v-if="item.amount" class="catalog-item__amount"><nobr>кол-во {{ item.amount }} шт.</nobr></span>
                        </div>
                        <div class="catalog-item__subtitle">{{ item.type }}</div>
                        <div class="catalog-item__properties">
                            <div class="catalog-item__property" v-for="(value, property) in item.properties">
                                <div class="catalog-item__property-name">{{ property }}</div>
                                <div class="catalog-item__property-value">{{ value }}</div>
                            </div>
                        </div>
                        <div class="catalog-item__price">
                            <span class="catalog-item__old-price" v-if="item.oldPrice">{{ item.oldPrice }}</span> {{ item.price }}
                        </div>
                    </div>
                    <div class="catalog-item__special" v-if="item.special">{{ item.special }}</div>
                    <div class="catalog-item__order" v-on:click="openOrderModal(item.name)">
                        {{ currentSection.button }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    module.exports = {
        data: function() {
            return {
                catalog: '',
                currentSection: '',
                isOrderModalActive: false
            }
        }, 
        methods: {
            openOrderModal: function(product) {
                this.$emit('open-order-modal', product)
            },
        },
        created() {
            function isHashFound(section) {
                if (section.code == this.code) {
                    return true;
                }
            }

            this.axios.get('/catalog.json').then((response) => {
                this.catalog = response.data;
                if (window.location.hash) {
                    var foundSection = this.catalog.find(isHashFound, {code: window.location.hash.substring(1)});
                    if (foundSection) {
                        this.currentSection = foundSection;
                    } else {
                        this.currentSection = this.catalog[0];
                    };
                } else {
                    this.currentSection = this.catalog[0];
                };
            });
        }
    }
</script>

<style lang="scss">
    @import "../scss/layout/layout";

    .catalog {
        background: url(/assets/img/pattern1.jpg);

        &__container {
            @include container;
            padding: 20px 0 35px 0;
        }

        &__menu {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(xs) {
                flex-direction: column;
            }
        }

        &__menu-item {
            display: flex;
            align-items: center;
            max-width: 390px;
            background: #2e2e2e;
            cursor: pointer;

            &--active {
                background: #0087d2;
            }

            @include media-breakpoint-down(lg) {
                max-width: 300px;
            }

            @include media-breakpoint-down(md) {
                max-width: 225px;
            }

            @include media-breakpoint-down(sm) {
                max-width: 165px;
            }

            @include media-breakpoint-down(xs) {
                max-width: none;
                margin-bottom: 5px;
            }
        }

        &__menu-icon {
            padding: 20px 10px 20px 15px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &__menu-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: #ffffff;
            text-transform: uppercase;
            padding: 25px 10px 25px 0;

            @include media-breakpoint-down(md) {
                padding-left: 25px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 25px 25px 5px 25px;
            background: #0087d2;
        }

        &__item {
            margin: 0 0 20px 0;
            flex: 1 1 auto;
        }
    }

    .catalog-item {
        display: flex;
        flex-wrap: wrap;
        
        position: relative;
        background: #ffffff;
        padding: 20px;
        max-width: 535px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 442px;
            flex-direction: column;
        }

        @include media-breakpoint-down(md) {
            flex: 0 0 322px;
            padding-top: 50px;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
        }

        &__image {
           text-align: center;
        }

        &__content {
            min-width: 290px;
            @include media-breakpoint-down(sm) {
                min-width: initial;
            }
        }

        &__title {
            font-size: 21px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &__subtitle {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            max-width: 350px;
        }

        &__amount {
            font-size: 14px;
            font-weight: 300;
            color: #0087d2;
            text-transform: none;
        }

        &__properties {
            padding: 20px 0 20px 0;
        }

        &__property {
            display: flex;
            flex-wrap: wrap;
        }

        &__property-name {
            width: 60%;
            font-size: 15px;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                width: auto;
                margin-right: 7px;
            }
        }

        &__property-value {
            width: 40%;
            font-size: 14px;
            font-weight: 200;
            color: #838383;

            @include media-breakpoint-down(sm) {
                width: auto;
            }
        }

        &__price {
            font-size: 16px;
            font-weight: 700;
            position: absolute;
            bottom: 10px;
        }

        &__old-price {
            font-size: 12px;
            font-weight: 300;
            text-decoration: line-through;
            padding-right: 5px;
        }

        &__order {
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            color: #ffffff;
            background: #2e2e2e;
            padding: 10px 20px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                right: initial;
                bottom: initial;
                left: 0;
                top: 0;
            }
        }

        &__special {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            color: #ffffff;
            background: #e41717;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
</style>