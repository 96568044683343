<template>
    <div class="header">
        <div class="header__menu header-menu" :class="{'header-menu--fixed': isMenuFixed}">
            <div class="header-menu__container">
                <div class="menu">
                    <button class="menu__toggler" v-on:click="isSidebarOpened = !isSidebarOpened"></button>
                    <div class="menu__list">
                        <a href="#" class="menu__link" v-scroll-to="{el: '.catalog', offset: -30}">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'catalog'}">Оборудование</div>
                        </a>
                        <a href="#" class="menu__link" v-scroll-to="'.advantages'">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'advantages'}">Наши преимущества</div>
                        </a>
                        <a href="#" class="menu__link" v-scroll-to="'.special'">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'special'}">Акции</div>
                        </a>
                        <a href="#" class="menu__link" v-scroll-to="'.stages'">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'stages'}">Этапы работы</div>
                        </a>
                        <a href="#" class="menu__link" v-scroll-to="'.partners'">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'partners'}">Наши клиенты</div>
                        </a>
                        <a href="#" class="menu__link" v-scroll-to="'.feedback'" @click="submitContactsGoal">
                            <div class="menu__item" :class="{'menu__item--active': this.currentSection == 'feedback'}">Контакты</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <transition name="sidebar-slide">
            <div class="sidebar" v-if="isSidebarOpened">

                <a class="sidebar__close close-sidebar">
                    <span class="close-icon" v-on:click="isSidebarOpened = false"></span>
                </a>
                <div class="sidebar__content">
                    <div class="sidebar__phone">
                        <a href="tel:+78007753205">8-800-775-32-05</a>
                    </div>
                    <div class="sidebar__callback">
                        <a @click.prevent="openOrderModal" href="#" class="callback-button">заказать звонок</a>
                    </div>
                    <div class="sidebar__menu sidebar-menu">
                        <div class="sidebar-menu__list">
                            <a href="#" class="sidebar-menu__link" v-scroll-to="{el: '.catalog', offset: -30}" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'catalog'}">Оборудование</div>
                            </a>
                            <a href="#" class="sidebar-menu__link" v-scroll-to="'.advantages'" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'advantages'}">Наши преимущества</div>
                            </a>
                            <a href="#" class="sidebar-menu__link" v-scroll-to="'.special'" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'special'}">Акции</div>
                            </a>
                            <a href="#" class="sidebar-menu__link" v-scroll-to="'.stages'" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'stages'}">Этапы работы</div>
                            </a>
                            <a href="#" class="sidebar-menu__link" v-scroll-to="'.partners'" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'partners'}">Наши клиенты</div>
                            </a>
                            <a href="#" class="sidebar-menu__link" v-scroll-to="'.feedback'" v-on:click="isSidebarOpened = false">
                                <div class="sidebar-menu__item" :class="{'sidebar-menu__item--active': this.currentSection == 'feedback'}">Контакты</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    module.exports = {
        name: 'Header',
        data: function() {
            return {
                isSidebarOpened: false,
            }
        },
        props: ['currentSection', 'isMenuFixed'],
        methods: {
            openOrderModal: function(product) {
                this.$emit('open-order-modal')
            },
            submitContactsGoal: function() {
                yaCounter48380513.reachGoal('najali_kontakti', function () {
                    console.log('Goal reached: najali_kontakti');
                });
            },
        },
        created() {

        },
    }
</script>

<style lang="scss">
    @import "../scss/layout/layout";

    .header-menu {
        background: #2e2e2e;

        &--fixed {
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 40;
        }

        &__container {
            @include container;
            padding: 0;
        }
    }

    .menu {

        &__toggler {
            background: url(/assets/img/menu-icon.svg) no-repeat center center;
            background-size: 100% 100%;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            border: none;
            cursor: pointer;
            display: none;
            margin: 10px 20px;

            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &__item {
            color: #ffffff;
            padding: 24px 30px;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            @include media-breakpoint-down(lg) {
                padding-left: 25px;
                padding-right: 25px;
            }

            @include media-breakpoint-down(md) {
                padding-left: 6px;
                padding-right: 6px;
            }

            &--active {
                background: #0087d2;
            }

            &:hover {
                background: rgba(0,135,210,0.4);
            }
        }

        &__link {
            text-decoration: none;

            &:active {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
                border: none;
            }
        }
    }

    .sidebar {
        height: 100%;
        width: 300px;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0px;
        background-color: #ffffff;
        border-right: 1px solid #e5e5e5;
        overflow-x: hidden;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        padding-top: 50px;
        z-index: 50;

        &__logo {
            position: absolute;
            top: 20px;
            right: 60px;
            left: 15px;
        }

        &__close {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 10px;
        }

        &__content {
            padding: 15px;
        }

        &__phone {
            font-size: 24px;
            font-weight: 600;
            text-align: center;

            & a {
                color: #000000;
                text-decoration: none;
            }
        }

        &__callback {
            text-align: center;
        }

        &__menu {
            margin-top: 20px;
        }
    }

    .sidebar-menu {

        &__list {
            
        }

        &__item {
            background: #000000;
            color: #ffffff;
            padding: 24px 30px;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 2px;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            @include media-breakpoint-down(lg) {
                padding-left: 25px;
                padding-right: 25px;
            }

            @include media-breakpoint-down(md) {
                padding-left: 6px;
                padding-right: 6px;
            }

            &--active {
                background: #0087d2;
            }

            &:hover {
                background: rgba(0,135,210,0.4);
            }
        }

        &__link {
            text-decoration: none;

            &:active {
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
                border: none;
            }
        }
    }

    

    .close-icon {
        background-image: url(/assets/img/close.svg);
        width: 30px;
        height: 30px;
        display: block;
    }

    .sidebar-slide-enter-active, .sidebar-slide-leave-active {
        transition: all .5s;
    }
    .sidebar-slide-enter, .sidebar-slide-leave-to {
        left: -300px;
    }
    .sidebar-slide-enter-to, .sidebar-slide-leave {
        left: 0px;
    }

</style>