var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog" }, [
    _c("div", { staticClass: "catalog__container" }, [
      _c(
        "div",
        { staticClass: "catalog__menu" },
        _vm._l(_vm.catalog, function(section) {
          return _c(
            "div",
            {
              staticClass: "catalog__menu-item",
              class: {
                "catalog__menu-item--active": _vm.currentSection == section
              },
              on: {
                click: function($event) {
                  _vm.currentSection = section
                }
              }
            },
            [
              _c("div", { staticClass: "catalog__menu-icon" }, [
                _c("img", { attrs: { src: section.icon } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "catalog__menu-title" }, [
                _vm._v(_vm._s(section.title))
              ])
            ]
          )
        })
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "catalog__list" },
        _vm._l(_vm.currentSection.items, function(item) {
          return _c("div", { staticClass: "catalog__item catalog-item" }, [
            _c("div", { staticClass: "catalog-item__image" }, [
              _c("img", { attrs: { src: item.image } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "catalog-item__content" }, [
              _c("div", { staticClass: "catalog-item__title" }, [
                _vm._v("\n                        " + _vm._s(item.name) + " "),
                item.amount
                  ? _c(
                      "span",
                      { staticClass: "catalog-item__amount" },
                      [
                        _c("nobr", [
                          _vm._v("кол-во " + _vm._s(item.amount) + " шт.")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "catalog-item__subtitle" }, [
                _vm._v(_vm._s(item.type))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "catalog-item__properties" },
                _vm._l(item.properties, function(value, property) {
                  return _c("div", { staticClass: "catalog-item__property" }, [
                    _c("div", { staticClass: "catalog-item__property-name" }, [
                      _vm._v(_vm._s(property))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "catalog-item__property-value" }, [
                      _vm._v(_vm._s(value))
                    ])
                  ])
                })
              ),
              _vm._v(" "),
              _c("div", { staticClass: "catalog-item__price" }, [
                item.oldPrice
                  ? _c("span", { staticClass: "catalog-item__old-price" }, [
                      _vm._v(_vm._s(item.oldPrice))
                    ])
                  : _vm._e(),
                _vm._v(" " + _vm._s(item.price) + "\n                    ")
              ])
            ]),
            _vm._v(" "),
            item.special
              ? _c("div", { staticClass: "catalog-item__special" }, [
                  _vm._v(_vm._s(item.special))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "catalog-item__order",
                on: {
                  click: function($event) {
                    _vm.openOrderModal(item.name)
                  }
                }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.currentSection.button) +
                    "\n                "
                )
              ]
            )
          ])
        })
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5b5d37d9", { render: render, staticRenderFns: staticRenderFns })
  }
}