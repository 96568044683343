import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

const VueInputMask = require('vue-inputmask').default;
Vue.use(VueInputMask);

import { ScrollContainer, ScrollItem } from 'vue-scrollmonitor';
import headerMenu from './../components/header-menu.vue';
import catalog from './../components/catalog.vue';
import special from './../components/special.vue';
import partners from './../components/partners.vue';

var app = new Vue({
  el: '.app',

  data: function() {
      return {
          state: {},
          isOrderModalActive: false,
          selectedProduct: '',
          isMailSent: false,
          feedback: {
            name: '',
            phone: '',
            email: '',
            message: '',
            product: '',
          },
          formSendResult: ''
      }
  }, 
  
  components: {
    ScrollContainer,
    ScrollItem,
    headerMenu: headerMenu,
    catalog: catalog,
    special: special,
    partners: partners,
  },

  methods: {
    openOrderModal: function(product) {
      this.isOrderModalActive = true;
      if (product) {
        this.selectedProduct = product;
      } else {
        this.selectedProduct = '';
      };
      this.isMailSent = false;
    },
    closeOrderModal: function() {
      this.isOrderModalActive = false;
    },
    submitForm: function() {
      this.feedback.product = this.selectedProduct;
      axios.post('/mail.php', this.feedback).then(response => {
        console.log(response.data);
        this.isMailSent = true;
        yaCounter48380513.reachGoal('zapolnili_formu', function () {
          console.log('Goal reached: zapolnili_formu');
        });
        this.feedback = {
          name: '',
          phone: '',
          email: '',
          message: '',
          product: '',
        };
      });

    },
    updateState (state) {
      this.state = state;
    },
  },

  computed: {
    firstSectionInViewport() {
        for(var key in this.state) {
            if (this.state[key].isInViewport) {
                return key;
            }
        };
    },
    isMenuFixed() {
        for(var key in this.state) {
            if (this.state[key].isAboveViewport && !this.state[key].isInViewport) {
                return true;
            }
        };
    },
  },

});


// Modal https://lukevers.com/2015/08/17/crafting-a-login-modal-with-vuejs
// Build a Custom JavaScript Scrollspy Navigation https://scotch.io/tutorials/build-a-custom-javascript-scrollspy-navigation
// https://medium.com/@codebyjeff/vue-js-simple-tuts-toggling-b80edddee1ac