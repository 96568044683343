var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partners" }, [
    _c(
      "div",
      { staticClass: "partners__container" },
      [
        _c("div", { staticClass: "partners__title title" }, [
          _vm._v("Наши клиенты")
        ]),
        _vm._v(" "),
        _c("slick", { ref: "slick", attrs: { options: _vm.slickOptions } }, [
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners1.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners2.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners3.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners4.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners1.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners2.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners3.jpg" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__item" }, [
            _c("img", { attrs: { src: "/assets/img/partners4.jpg" } })
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-62057d1b", { render: render, staticRenderFns: staticRenderFns })
  }
}