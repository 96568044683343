<template>
    <div class="special">
        <div class="special__title-wrapper">
            <div class="special__title">Акции</div>
        </div>
        <slick ref="slick" :options="slickOptions">

            <div class="special__slide special-slide">
                <div class="special-slide__wrapper">
                    <div class="special-slide__caption">
                        <div class="special-slide__caption-content">
                            <div class="special-slide__title">-10% на аренду Graco Mark V</div>
                            <div class="special-slide__text">Аппарат безвоздушного распыления Mark V предназначен для нанесения материалов высокой и сверхвысокой вязкости с высоким содержанием сухого остатка. Graco Mark V  широко применяется компаниями во всем мире для нанесения огнезащитных, гидроизоляционных, теплоизоляционных, антикоррозийных и других вязких материалов. Универсальность: один распылитель обеспечивает нанесение, как отделочных покрытий, так и шпаклевок. Возможность наносить лаки, акриловые краски, покрытия на масляной основе, эмульсия, эмали, шпаклевки, уретаны, огнезащитные покрытия, латексы, штукатурки для внутренних работ, эпоксидные составы, цинконаполненные составы, мастики, штукатурки для внутренних работ.</div>
                            <button class="special-slide__button" @click="openModal">Заказать по акции</button>
                        </div>
                    </div>
                    <div class="special-slide__image">
                        <img src="/assets/img/slide1.jpg">
                    </div>
                </div>
            </div>

            <div class="special__slide special-slide">
                <div class="special-slide__wrapper">
                    <div class="special-slide__caption">
                        <div class="special-slide__caption-content">
                            <div class="special-slide__title">-15% на аренду Graco ST MAX II 495</div>
                            <div class="special-slide__text">Graco ST MAX II 495 — поршневой безвоздушный окрасочный аппарат высокого давления, подходит для распыления водорастворимых и акриловых красок, алкидных составов, лаков на водной основе, грунтовок, эпоксидных материалов, олиф, вододисперсионных эмалей, эмульсий, клея, полиуретановых составов, морилки и с других пропитывающих веществ. Область применения окрасочного аппарата Graco ST MAX II 495  достаточно широка: внутренние и наружные окрасочные работы; покраска потолков, пола и стен при квартирном ремонте, обработка древесины, качественные финишные покрытия, антикоррозийная обработка и окраска автомобилей. Аппарат Graco ST MAX II 495 — это отличное решение как для небольших бригад маляров, так и для огромных сервисных строительных центров и компаний.</div>
                            <button class="special-slide__button" @click="openModal">Заказать по акции</button>
                        </div>
                    </div>
                    <div class="special-slide__image">
                        <img src="/assets/img/slide2.jpg">
                    </div>
                </div>
            </div>


        </slick>

    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        name: 'Special',
        data: function() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    autoplay: true,
                    prevArrow: '<button type="button" class="slick-prev"></button>',
                    nextArrow: '<button type="button" class="slick-next"></button>',
                    responsive: [
                        {
                          breakpoint: 720,
                          settings: {
                            slidesToShow: 1,
                          }
                        },
                        {
                          breakpoint: 540,
                          settings: {
                            slidesToShow: 1,
                          }
                        },
                    ]
                },
            }
        }, 
        components: {
            Slick
        },
        methods: {
            openModal() {
                this.$emit('open-order-modal');
            },
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
        },
        created() {
            
        }
    }
</script>

<style lang="scss">
    @import "../scss/layout/layout";
    @import "../../node_modules/slick-carousel/slick/slick.css";

    .special {
        padding-top: 95px;

        &__title-wrapper {
            text-align: center;
        }

        &__title {
            color: #ffffff;
            font-size: 36px;
            font-weight: 800;
            background: #292929;
            padding: 10px 30px;
            display: inline-block;
            position: relative;
            bottom: -29px;
            z-index: 1;
            text-transform: uppercase;

            @include media-breakpoint-down(lg) {
                background: transparent;
                color: #292929;
                position: static;
                margin-bottom: 20px;
            }
        }

        & .slick-slider {
            
        }

        & .slick-list {
            
        }

        & .slick-slide img {
            margin: 0 auto;
            max-width: 100%
        }

        & .slick-arrow {
            width: 50px;
            height: 50px;
            background: #0087d2;
            border-radius: 25px;
            border: none;
            flex-basis: 50px;
            flex-shrink: 0;
            position: absolute;
            top: calc(50% - 25px);
            z-index: 10;
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                width: 13px;
                height: 18px;
                position: relative;
                top: 1px;
            }

            &.slick-prev {
                left: 5%;
            }

            &.slick-next {
                right: 5%;
            }

            &.slick-prev:before {
                background: url(/assets/img/arrow-left.png);
                left: -2px;
            }

            &.slick-next:before {
                background: url(/assets/img/arrow-right.png);
                left: 2px;
            }
        }
    }

    .special-slide {
        background: #efefef;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }
        
        &__caption {
            flex: 1 1 50%;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(lg) {
                padding: 20px 40px;
            }
        }

        &__caption-content {
            max-width: 580px;
        }

        &__title {
            margin-bottom: 60px;
            font-size: 30px;
            font-weight: 800;
            position: relative;

            @include media-breakpoint-down(lg) {
                font-size: 24px;;
            }

            &:before {
                content: '';
                display: block;
                width: 50px;
                border-bottom: 3px solid #0087d2;
                position: absolute;
                bottom: -20px;
                left: 0;
            }
        }

        &__text {
            font-size: 16px;
            font-weight: 200;
            line-height: 20px;
        }

        &__button {
            font-family: 'PF Beau Sans Pro';
            margin-top: 35px;;
            color: #ffffff;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            background: #0087d2;
            padding: 17px 25px;
            border: none;
            display: inline-block;

            @include media-breakpoint-down(lg) {
                font-size: 14px;;
            }
        }

        &__image {
            flex: 1 1 50%;
        }
    }
</style>