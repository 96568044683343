var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "div",
        {
          staticClass: "header__menu header-menu",
          class: { "header-menu--fixed": _vm.isMenuFixed }
        },
        [
          _c("div", { staticClass: "header-menu__container" }, [
            _c("div", { staticClass: "menu" }, [
              _c("button", {
                staticClass: "menu__toggler",
                on: {
                  click: function($event) {
                    _vm.isSidebarOpened = !_vm.isSidebarOpened
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "menu__list" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: { el: ".catalog", offset: -30 },
                        expression: "{el: '.catalog', offset: -30}"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active": this.currentSection == "catalog"
                        }
                      },
                      [_vm._v("Оборудование")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: ".advantages",
                        expression: "'.advantages'"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active":
                            this.currentSection == "advantages"
                        }
                      },
                      [_vm._v("Наши преимущества")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: ".special",
                        expression: "'.special'"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active": this.currentSection == "special"
                        }
                      },
                      [_vm._v("Акции")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: ".stages",
                        expression: "'.stages'"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active": this.currentSection == "stages"
                        }
                      },
                      [_vm._v("Этапы работы")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: ".partners",
                        expression: "'.partners'"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active":
                            this.currentSection == "partners"
                        }
                      },
                      [_vm._v("Наши клиенты")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: ".feedback",
                        expression: "'.feedback'"
                      }
                    ],
                    staticClass: "menu__link",
                    attrs: { href: "#" },
                    on: { click: _vm.submitContactsGoal }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "menu__item",
                        class: {
                          "menu__item--active":
                            this.currentSection == "feedback"
                        }
                      },
                      [_vm._v("Контакты")]
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "sidebar-slide" } }, [
        _vm.isSidebarOpened
          ? _c("div", { staticClass: "sidebar" }, [
              _c("a", { staticClass: "sidebar__close close-sidebar" }, [
                _c("span", {
                  staticClass: "close-icon",
                  on: {
                    click: function($event) {
                      _vm.isSidebarOpened = false
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar__content" }, [
                _c("div", { staticClass: "sidebar__phone" }, [
                  _c("a", { attrs: { href: "tel:+78007753205" } }, [
                    _vm._v("8-800-775-32-05")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sidebar__callback" }, [
                  _c(
                    "a",
                    {
                      staticClass: "callback-button",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openOrderModal($event)
                        }
                      }
                    },
                    [_vm._v("заказать звонок")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sidebar__menu sidebar-menu" }, [
                  _c("div", { staticClass: "sidebar-menu__list" }, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: { el: ".catalog", offset: -30 },
                            expression: "{el: '.catalog', offset: -30}"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "catalog"
                            }
                          },
                          [_vm._v("Оборудование")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: ".advantages",
                            expression: "'.advantages'"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "advantages"
                            }
                          },
                          [_vm._v("Наши преимущества")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: ".special",
                            expression: "'.special'"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "special"
                            }
                          },
                          [_vm._v("Акции")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: ".stages",
                            expression: "'.stages'"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "stages"
                            }
                          },
                          [_vm._v("Этапы работы")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: ".partners",
                            expression: "'.partners'"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "partners"
                            }
                          },
                          [_vm._v("Наши клиенты")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: ".feedback",
                            expression: "'.feedback'"
                          }
                        ],
                        staticClass: "sidebar-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.isSidebarOpened = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-menu__item",
                            class: {
                              "sidebar-menu__item--active":
                                this.currentSection == "feedback"
                            }
                          },
                          [_vm._v("Контакты")]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5937f55f", { render: render, staticRenderFns: staticRenderFns })
  }
}